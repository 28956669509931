<!--
 * @Description: 巡检管理--巡检详情页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors: FCC
 * @LastEditTime: 2019-09-24 10:59:14
 -->
<template>
  <div class="patrolLogManagerForm-wrapper">
    <form-panel
      ref="formPanel"
      :form="form"
      submitText="保存"
      v-bind="submitConfig"
      :submitUrl="submitUrl"
      :footerShow="footerShow"
      :submitSuccess="submitSuccess"
      :submitError="submitError"
    >
      <col2-detail>
        <col2-block title="基本信息">
          <col2-item label="设备名称" :text="detailItem.name" />
          <col2-item label="巡检周期" :text="detailItem.patrolCycle" />
          <col2-item label="设备编号" :text="detailItem.serialNum" />
          <col2-item label="巡检日期" :text="detailItem.patrolDate" />
          <col2-item label="归属项目" :text="detailItem.communityName" />
          <col2-item label="所属组团" :text="detailItem.area" />
          <col2-item label="安装位置" :text="detailItem.address" />
          <col2-item label="负责人" :text="detailItem.chargerUserName" />
        </col2-block>
        <col2-block title="巡检反馈">
          <col2-item
            v-if="showFinish"
            :span="24"
            label="执行方式"
            :text="detailItem.patrolType"
          />
          <col2-item
            v-if="showFinish"
            :span="24"
            label="巡检状态"
            :text="detailItem.statusName"
          />
          <col2-item
            v-if="showFinish"
            :span="24"
            label="巡检执行人"
            :text="detailItem.executorName"
          />
          <col2-item
            v-if="showFinish"
            :span="24"
            label="巡检完成时间"
            :text="detailItem.finishDate"
          />
          <el-form-item label="巡检要点">
            <div v-for="(item, index) in detailItem.patrolPointList" :key="index">
              <div class="content-wrapper">
                <span class="badge-wrapper">{{ index + 1 }}</span>
                <span class="left-text">{{item.pointName}}</span>
                <span :class="['middle-text', {'red-text': !item.normal}]">{{ item.normal ? "正常；" : "异常；" }}</span>
                <span v-if="!item.normal" class="middle-text">巡检报事信息：工单编号&nbsp;</span>
                <div v-if="!item.normal && item.reportList && item.reportList.length">
                  <div
                    v-for="(order, orderIndex) in item.reportList"
                    :key="orderIndex">
                    <v-button
                    type="text"
                    :text="order.caseNo"
                    @click="orderClick(order)"
                    ></v-button>
                    <span>
                      <template v-if="detailItem.newOrder">
                        【{{ newOrderStatusMap[order.caseStatus] }}】
                      </template>
                      <template v-else>
                        【{{ orderStatusMap[order.caseStatus] }}】
                      </template>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item  label="抄表信息">
            <div v-for="(item, index) in detailItem.patrolPanelList" :key="index">
              <div class="content-wrapper">
                <span class="badge-wrapper">{{ index + 1 }}</span>
                <span class="left-text" >{{item.showPanelContent}}</span>
              </div>
            </div>
          </el-form-item>
          <col2-item v-if="showFinish" :span="24" label="巡检照片">
            <div>
              <v-preview :imgs="imageUrls"></v-preview>
            </div>
          </col2-item>
          <col2-item v-if="showFinish" :span="24" label="巡检描述">
            <div v-html="detailItem.remarks"></div>
          </col2-item>
        </col2-block>
        <col2-block
          v-if="footerShow"
          title="操作信息"
          class="col2-block-wrapper"
        >
          <!-- 未巡检，可操作 -->
          <el-form-item
            class="el-margin"
            label="操作"
            prop="status"
            :rules="[
              {
                required: true,
                message: '当前选项不允许为空',
                trigger: 'change'
              }
            ]"
          >
            <v-select v-model="form.status" :options="handleOps"></v-select>
          </el-form-item>
          <el-form-item
            label="操作备注"
            prop="remarks"
            :rules="[
              { required: true, message: '请输入操作备注', trigger: 'blur' }
            ]"
          >
            <v-ueditor v-model="form.remarks"></v-ueditor>
          </el-form-item>
        </col2-block>
        <col2-block
          v-if="detailItem.status === 4"
          title="操作信息"
          class="col2-block-wrapper"
        >
          <!-- 作废 -->
          <col2-item :span="24" label="状态" :text="detailItem.statusName" />
          <col2-item :span="24" label="操作人" :text="detailItem.operateName" />
          <col2-item
            :span="24"
            label="操作时间"
            :text="detailItem.operateTime"
          />
          <col2-item :span="24" label="操作备注">
            <div v-html="detailItem.remarks"></div>
          </col2-item>
        </col2-block>
        <col2-block v-if="detailItem.status === 5" title="操作信息">
          <!-- 漏检 -->
          <col2-item :span="24" label="状态" :text="detailItem.statusName" />
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>

import { getPatrolLogDetailURL, submitUrl } from './api'
import { handleOps, orderStatusMap, newOrderStatusMap } from './map'
import { Col2Block, Col2Item, Col2Detail } from 'components/bussiness'
import { vPreview, vUeditor } from 'components/control'

export default {
  name: 'patrolLogManagerForm',
  components: {
    Col2Block,
    Col2Item,
    Col2Detail,
    vPreview,
    vUeditor
  },
  data () {
    return {
      submitUrl: submitUrl,
      handleOps: handleOps,
      orderStatusMap: orderStatusMap,
      newOrderStatusMap,
      submitConfig: {
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      detailItem: {},
      communityId: '',
      form: {
        remarks: '',
        dataObject: ''
      },
      imageUrls: [],
      footerShow: false,
      // 巡检已完成
      showFinish: false
    }
  },

  created () {
    // 设置title
    this.$setBreadcrumb('巡检日志详情')
  },

  // 加载详情数据
  mounted () {
    this.getDetailData()
  },

  methods: {

    // 工单编号点击的处理
    orderClick (order) {
      // 跳转工单3.0
      if (this.detailItem.newOrderV3) {
        const menuInfo = this.$store.getters['menuStore/getMenuInfo'](947241)
        this.$router.push({
          name:'iframePanel',
          query:{
            pid:1,
            cid:3400,
            fid:94751,
            sid:947241,
            src: menuInfo.item.url,
            orderId: order.caseId,
            isReplace: 1
          }
        })
      } else {
        // 跳转到工单页面
        if(this.detailItem.newOrder) {
          // 新工单
          this.$router.push({
            name: 'orderSchedulingForm',
            query: {
              id: order.caseId,
            }
          })
        }else {
          // 老工单
          this.$router.push({
            name: 'serviceSheetForm',
            query: {
              id: order.caseId,
              orderCode: order.caseNo,
              communityId: this.communityId,
              noEdit: '1'
            }
          })
        }
      }


    },

    /// 获取详情数据
    getDetailData () {
      let _this = this
      let getParames = {
        params: {
          id: this.$route.query.id,
          ownerId: this.$route.query.ownerId
        }
      }
      this.$axios.get(getPatrolLogDetailURL, getParames).then(res => {
        if (res.status === 100) {
          _this.detailItem = res.data || {}
          // 1待巡检 3超时待巡检
          _this.footerShow = _this.detailItem.status === 1 || _this.detailItem.status === 3
          // 目前已巡检和超时已巡检都是返回2，为了防止以后再新增，使用一个变量接受下
          _this.showFinish = _this.detailItem.status === 2
          _this.communityId = res.data.communityId || ''
          // 处理巡检照片
          if (_this.showFinish && res.data.images) {
            let imgList = res.data.images
            let tempList = []
            imgList.forEach(item => {
              tempList.push({
                src: item
              })
            })
            _this.imageUrls = tempList
          }

          // 处理负责人和执行人
          let chargerUserName = _this.detailItem.chargerUserName ? _this.detailItem.chargerUserName : ''
          let chargerUserPhone = _this.detailItem.chargerUserPhone ? `（${_this.detailItem.chargerUserPhone}）` : ''
          _this.detailItem.chargerUserName = chargerUserName ? chargerUserName + chargerUserPhone : ''

          let executorName = _this.detailItem.executorName ? _this.detailItem.executorName : ''
          let executorMobile = _this.detailItem.executorMobile ? `（${_this.detailItem.executorMobile}）` : ''
          _this.detailItem.executorName = executorName ? executorName + executorMobile : ''

          // 处理抄表信息 patrolPanelList
          if (_this.detailItem.patrolPanelList) {
            _this.detailItem.patrolPanelList.forEach(item => {
              // 抄表类型名字
              let showPanelContent = item.panelName || ''
              if (showPanelContent.length) {
                // 抄表内容
                let panelData = item.panelData || ''
                if (panelData && panelData.length) {
                  // 抄表内容单位
                  let panelUnit = item.panelUnit || ''
                  showPanelContent = `${showPanelContent}： ${panelData}${panelUnit}；`
                }
              }
              item.showPanelContent = showPanelContent
            })
          }

          if (_this.footerShow) {
            _this.updatForm()
          }
        }
      })
    },

    // 更新form数据
    updatForm () {
      let form = {
        id: this.detailItem.id,
        remarks: '',
        status: undefined,
        dataObject: this.detailItem.name
      }

      this.form = Object.assign({}, form)
    },

    /// 提交成功的处理
    submitSuccess (data) {
      // 跳转到列表
      this.$router.go(-1)
    },

    /// 提交失败的处理
    submitError (data) {
      console.log('submitError', data)
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.patrolLogManagerForm-wrapper {
  .content-wrapper {
    display: flex;

    .badge-wrapper {
      margin-top: 8px;
      height: 20px;
      display: inline-block;
      width: 20px;
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      background-color: #3ebcef;
      border-radius: 10px;
    }

    .left-text {
      margin-right: 20px;
      margin-left: 8px;
    }

    .middle-text {
      margin-right: 20px;
    }

    .red-text {
      color: red;
    }
  }

  .el-form-wrapper {
    width: 100%;
  }
}
</style>
