// 获取列表
const getListURL = `${API_CONFIG.baseURL}smDeviceAction!patrolLogList.action`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}devicePatrol/exportPatrolLogToExcel`
// 初始化本月新设备巡检日志
const handlePatrolLogInitURL = `${API_CONFIG.butlerBaseURL}devicePatrol/generateDevicePatrolNow`
// 获取详情
// const getPatrolLogDetailURL = `${API_CONFIG.baseURL}smDeviceAction!queryPatrolLog.action`
const getPatrolLogDetailURL = `${API_CONFIG.butlerBaseURL}devicePatrol/queryPatrolLog`

// 保存操作
const submitUrl = `${API_CONFIG.baseURL}smDeviceAction!invalidPatrol.action`

export {
  getListURL,
  exportListURL,
  handlePatrolLogInitURL,
  getPatrolLogDetailURL,
  submitUrl
}
 