var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "patrolLogManagerForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitText: "保存",
              submitUrl: _vm.submitUrl,
              footerShow: _vm.footerShow,
              submitSuccess: _vm.submitSuccess,
              submitError: _vm.submitError,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基本信息" } },
                [
                  _c("col2-item", {
                    attrs: { label: "设备名称", text: _vm.detailItem.name },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "巡检周期",
                      text: _vm.detailItem.patrolCycle,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "设备编号",
                      text: _vm.detailItem.serialNum,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "巡检日期",
                      text: _vm.detailItem.patrolDate,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "归属项目",
                      text: _vm.detailItem.communityName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: { label: "所属组团", text: _vm.detailItem.area },
                  }),
                  _c("col2-item", {
                    attrs: { label: "安装位置", text: _vm.detailItem.address },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "负责人",
                      text: _vm.detailItem.chargerUserName,
                    },
                  }),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "巡检反馈" } },
                [
                  _vm.showFinish
                    ? _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "执行方式",
                          text: _vm.detailItem.patrolType,
                        },
                      })
                    : _vm._e(),
                  _vm.showFinish
                    ? _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "巡检状态",
                          text: _vm.detailItem.statusName,
                        },
                      })
                    : _vm._e(),
                  _vm.showFinish
                    ? _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "巡检执行人",
                          text: _vm.detailItem.executorName,
                        },
                      })
                    : _vm._e(),
                  _vm.showFinish
                    ? _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "巡检完成时间",
                          text: _vm.detailItem.finishDate,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "巡检要点" } },
                    _vm._l(
                      _vm.detailItem.patrolPointList,
                      function (item, index) {
                        return _c("div", { key: index }, [
                          _c("div", { staticClass: "content-wrapper" }, [
                            _c("span", { staticClass: "badge-wrapper" }, [
                              _vm._v(_vm._s(index + 1)),
                            ]),
                            _c("span", { staticClass: "left-text" }, [
                              _vm._v(_vm._s(item.pointName)),
                            ]),
                            _c(
                              "span",
                              {
                                class: [
                                  "middle-text",
                                  { "red-text": !item.normal },
                                ],
                              },
                              [
                                _vm._v(
                                  _vm._s(item.normal ? "正常；" : "异常；")
                                ),
                              ]
                            ),
                            !item.normal
                              ? _c("span", { staticClass: "middle-text" }, [
                                  _vm._v("巡检报事信息：工单编号 "),
                                ])
                              : _vm._e(),
                            !item.normal &&
                            item.reportList &&
                            item.reportList.length
                              ? _c(
                                  "div",
                                  _vm._l(
                                    item.reportList,
                                    function (order, orderIndex) {
                                      return _c(
                                        "div",
                                        { key: orderIndex },
                                        [
                                          _c("v-button", {
                                            attrs: {
                                              type: "text",
                                              text: order.caseNo,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.orderClick(order)
                                              },
                                            },
                                          }),
                                          _c(
                                            "span",
                                            [
                                              _vm.detailItem.newOrder
                                                ? [
                                                    _vm._v(
                                                      "\n                      【" +
                                                        _vm._s(
                                                          _vm.newOrderStatusMap[
                                                            order.caseStatus
                                                          ]
                                                        ) +
                                                        "】\n                    "
                                                    ),
                                                  ]
                                                : [
                                                    _vm._v(
                                                      "\n                      【" +
                                                        _vm._s(
                                                          _vm.orderStatusMap[
                                                            order.caseStatus
                                                          ]
                                                        ) +
                                                        "】\n                    "
                                                    ),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "抄表信息" } },
                    _vm._l(
                      _vm.detailItem.patrolPanelList,
                      function (item, index) {
                        return _c("div", { key: index }, [
                          _c("div", { staticClass: "content-wrapper" }, [
                            _c("span", { staticClass: "badge-wrapper" }, [
                              _vm._v(_vm._s(index + 1)),
                            ]),
                            _c("span", { staticClass: "left-text" }, [
                              _vm._v(_vm._s(item.showPanelContent)),
                            ]),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                  _vm.showFinish
                    ? _c(
                        "col2-item",
                        { attrs: { span: 24, label: "巡检照片" } },
                        [
                          _c(
                            "div",
                            [
                              _c("v-preview", {
                                attrs: { imgs: _vm.imageUrls },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.showFinish
                    ? _c(
                        "col2-item",
                        { attrs: { span: 24, label: "巡检描述" } },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(_vm.detailItem.remarks),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.footerShow
                ? _c(
                    "col2-block",
                    {
                      staticClass: "col2-block-wrapper",
                      attrs: { title: "操作信息" },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "el-margin",
                          attrs: {
                            label: "操作",
                            prop: "status",
                            rules: [
                              {
                                required: true,
                                message: "当前选项不允许为空",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.handleOps },
                            model: {
                              value: _vm.form.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "操作备注",
                            prop: "remarks",
                            rules: [
                              {
                                required: true,
                                message: "请输入操作备注",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-ueditor", {
                            model: {
                              value: _vm.form.remarks,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.detailItem.status === 4
                ? _c(
                    "col2-block",
                    {
                      staticClass: "col2-block-wrapper",
                      attrs: { title: "操作信息" },
                    },
                    [
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "状态",
                          text: _vm.detailItem.statusName,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "操作人",
                          text: _vm.detailItem.operateName,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "操作时间",
                          text: _vm.detailItem.operateTime,
                        },
                      }),
                      _c(
                        "col2-item",
                        { attrs: { span: 24, label: "操作备注" } },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(_vm.detailItem.remarks),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.detailItem.status === 5
                ? _c(
                    "col2-block",
                    { attrs: { title: "操作信息" } },
                    [
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "状态",
                          text: _vm.detailItem.statusName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }